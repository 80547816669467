/* Container for the Invite Signup Page */
.invite-signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f7fa;
    padding: 20px;
    margin-left: -300px;
}

/* Card Styling */
.invite-signup-card {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
}

/* Logo Section */
.invite-signup-logo img {
    width: 100px;
    margin-bottom: 20px;
}

/* Heading Styling */
.invite-signup-card h1 {
    font-size: 24px;
    color: #004a70;
    margin-bottom: 20px;
}

/* Form Fields */
.invite-signup-form .form-group {
    margin-bottom: 15px;
    text-align: left;
}

.invite-signup-form label {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom: 5px;
}

.invite-signup-form input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    box-sizing: border-box;
}

/* Button Styles */
.invite-signup-button {
    width: 100%;
    padding: 12px;
    background-color: #004a70;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.invite-signup-button:hover {
    background-color: #003a5b;
}

/* Error or Success Message Styling */
.invite-signup-message {
    margin-top: 15px;
    font-size: 14px;
    color: #f55a42;
}

/* Responsive Design */
@media (max-width: 600px) {
    .invite-signup-card {
        padding: 30px;
        width: 90%;
    }
}
