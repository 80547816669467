/* src/components/MyClub.css */

/* Main content adjustments */
.myclub-main-content {
  padding: 0;
  width: 100%;
  padding-left: 20px;
}

/* Content Grid */
.myclub-content-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

/* Left Column */
.myclub-left-column {
  display: flex;
  flex-direction: column;
}

/* Right Column */
.myclub-right-column {
  display: flex;
  flex-direction: column;
}

/* Club Header Content */
.myclub-header-content {
  margin-bottom: 40px;
}

.myclub-club-name {
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.myclub-club-description {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
}

.myclub-club-location {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #777;
}

.myclub-location-icon {
  margin-right: 8px;
  color: #0073e6;
}

/* Section Titles */
.myclub-section-title {
  font-size: 24px;
  font-weight: bold;
  color: #004a70;
  margin-bottom: 20px;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 10px;
}

/* News Section */
.myclub-news-section {
}

.myclub-news-post {
  margin-bottom: 30px;
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.myclub-news-post-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.myclub-news-post-meta {
  font-size: 12px;
  color: #999;
  margin-bottom: 10px;
}

.myclub-news-post-content {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.myclub-news-post-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Comments Preview */
.myclub-comments-preview {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.myclub-comments-count {
  font-size: 14px;
  color: #0073e6;
  cursor: pointer;
  margin-bottom: 10px;
}

.myclub-comment {
  margin-bottom: 10px;
}

.myclub-comment-author {
  font-weight: bold;
  color: #333;
}

.myclub-comment-content {
  font-size: 14px;
  color: #555;
  margin-left: 10px;
}

/* Input to Add Comment */
.myclub-add-comment-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Fixes overflow and keeps input aligned properly */
}

/* Show More Posts Button */
.myclub-show-more-button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #0073e6;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.myclub-show-more-button:hover {
  background-color: #005bb5;
}

/* Members Section */
.myclub-members-section {
  margin-bottom: 40px;
}

.myclub-members-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.myclub-member-card {
  background-color: #fafafa;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.myclub-member-card:hover {
  background-color: #f0f0f0;
}

.myclub-member-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.myclub-member-name-role h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.myclub-member-name-role p {
  margin: 0;
  font-size: 14px;
  color: #777;
}

.myclub-member-toggle-icon {
  font-size: 16px;
  color: #777;
}

.myclub-member-details {
  padding: 0 15px 15px 15px;
}

.myclub-member-contact p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
  display: flex;
  align-items: center;
}

.myclub-member-contact p svg {
  margin-right: 8px;
  color: #0073e6;
}

.myclub-start-chat-button {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #0073e6;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.myclub-start-chat-button:hover {
  background-color: #005bb5;
}

/* Planes Section */
.myclub-planes-section {
  margin-bottom: 40px;
}

.myclub-planes-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;
}

.myclub-plane-card {
  background-color: #fafafa;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.myclub-plane-card:hover {
  background-color: #f0f0f0;
}

.myclub-plane-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.myclub-plane-info h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
  display: flex;
  align-items: center;
}

.myclub-plane-info h3 svg {
  margin-right: 8px;
  color: #0073e6;
}

.myclub-plane-info p {
  margin: 0;
  font-size: 14px;
  color: #555;
}

.myclub-plane-toggle-icon {
  font-size: 16px;
  color: #777;
}

.myclub-plane-details {
  padding: 0 15px 15px 15px;
}

.available-text {
  color: green;
  font-weight: bold;
  margin-top: 10px;
}

.unavailable-text {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Scrollbar Styling */
.myclub-members-list::-webkit-scrollbar,
.myclub-planes-list::-webkit-scrollbar {
  width: 8px;
}

.myclub-members-list::-webkit-scrollbar-track,
.myclub-planes-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.myclub-members-list::-webkit-scrollbar-thumb,
.myclub-planes-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.myclub-members-list::-webkit-scrollbar-thumb:hover,
.myclub-planes-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Modal Styles */
.myclub-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myclub-modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  max-width: 800px;
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  padding: 20px;
}

.myclub-modal-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 30px;
  cursor: pointer;
  color: #999;
}

.myclub-modal-post {
  margin-bottom: 20px;
}

.myclub-modal-comments {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
}

.myclub-modal-comments h4 {
  font-size: 22px;
  margin-bottom: 15px;
}

.myclub-modal-comment {
  border-top: 1px solid #e0e0e0;
  padding: 10px 0;
}

.myclub-comment-author {
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.myclub-comment-content {
  font-size: 16px;
  color: #555;
}

.myclub-add-comment-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.myclub-add-comment-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0073e6;
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.myclub-add-comment-button:hover {
  background-color: #005bb5;
}
.myclub-read-more-button {
  background: none;
  border: none;
  color: #0073e6;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  margin-top: 10px;
}

.myclub-read-more-button:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 992px) {
  .myclub-content-grid {
    grid-template-columns: 1fr;
  }

  .myclub-left-column,
  .myclub-right-column {
    grid-column: 1 / -1;
  }

  .myclub-right-column {
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .myclub-club-name {
    font-size: 28px;
  }

  .myclub-club-description {
    font-size: 16px;
  }

  .myclub-section-title {
    font-size: 20px;
  }

  .myclub-news-post-title {
    font-size: 18px;
  }

  .myclub-news-post-content {
    font-size: 14px;
  }

  .myclub-member-name-role h3,
  .myclub-plane-info h3 {
    font-size: 16px;
  }

  .myclub-member-contact p,
  .myclub-plane-info p {
    font-size: 12px;
  }

  .myclub-member-header,
  .myclub-plane-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .myclub-member-toggle-icon,
  .myclub-plane-toggle-icon {
    align-self: flex-end;
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  .myclub-main-content {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}