/* Global Styles */
.chat-wrapper {
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
}

.chat-container {
  display: flex;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 15px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

/* Chat List Styles */
.chat-list-container {
  width: 350px;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 15px 0 0 15px;
  overflow: hidden;
  position: relative;
}

.chat-header-bar {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.chat-search {
  flex-grow: 1;
  padding: 10px 15px;
  border-radius: 30px;
  border: none;
  background-color: #ececec;
  font-size: 16px;
  outline: none;
  margin-right: 10px;
  transition: all 0.3s ease;
}

.chat-search:focus {
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.chat-new-button {
  background-color: #192f3a;
  color: white;
  border: none;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.chat-new-button:hover {
  background-color: #0056b3;
}

.chat-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-card {
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-card.selected-chat {
  background-color: #192f3a;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.chat-card:hover {
  background-color: #f9f9f9;
}

.chat-card-name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.chat-card-last-message {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-card-time {
  font-size: 12px;
  color: #999;
  text-align: right;
}

/* Chat Window Styles */
.chat-window {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 0 15px 15px 0;
  overflow: hidden;
  position: relative;
}

.chat-header {
  padding: 20px;
  font-size: 22px;
  font-weight: bold;
  background-color: #192f3a;
  color: white;
  border-radius: 0 15px 0 0;
  display: flex;
  align-items: center;
}

.chat-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.chat-message {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin-bottom: 20px;
  position: relative;
}

.my-message {
  align-self: flex-end;
  text-align: right;
}

.other-message {
  align-self: flex-start;
  text-align: left;
}

.message-bubble {
  display: flex;
  align-items: center;
}

.chat-message-text {
  padding: 12px 20px;
  border-radius: 25px;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
  display: inline-block;
}

.my-message .chat-message-text {
  background-color: #192f3a;
  color: white;
}

.other-message .chat-message-text {
  background-color: #ececec;
  color: #333;
}

.message-info {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.chat-message-time {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.message-options {
  position: relative;
  display: inline-block;
}

.options-icon {
  cursor: pointer;
  margin-left: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 20px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 10px 15px;
  text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background 0.3s ease;
}

.dropdown-menu button:hover {
  background: #f0f0f0;
}

/* Edited Tag */
.edited-tag {
  font-size: 12px;
  color: #999;
  font-style: italic;
  margin-left: 5px;
}

/* Deleted Message Styling */
.chat-message-text i {
  color: #999;
}

.chat-input-container {
  display: flex;
  padding: 15px;
  background-color: white;
  border-top: 1px solid #ddd;
}

.chat-input {
  flex-grow: 1;
  padding: 12px 20px;
  border-radius: 30px;
  border: none;
  background-color: #f0f0f0;
  font-size: 16px;
  outline: none;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.chat-input:focus {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.chat-send-button {
  padding: 12px 20px;
  background-color: #192f3a;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.chat-send-button:hover {
  background-color: #0056b3;
}

.chat-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 18px;
  color: #999;
  background-color: #f9f9f9;
  border-radius: 0 0 15px 0;
}

/* Overlay Background */
.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Chat Overlay Styles for Mobile */
.chat-overlay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 85%;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  animation: slideUp 0.3s forwards;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

@keyframes slideUp {
  to {
    transform: translateY(0);
  }
}

.chat-overlay-header {
  padding: 15px;
  background-color: #192f3a;
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  touch-action: none;
}

.chat-overlay-header:active {
  cursor: grabbing;
}

.overlay-handle {
  width: 40px;
  height: 5px;
  background-color: #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.overlay-participant-name {
  font-size: 18px;
  font-weight: bold;
}

.chat-overlay-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.member-list-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.member-list-container {
  background-color: #ffffff;
  width: 400px;
  max-height: 80vh;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.member-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #192f3a;
  color: white;
}

.member-search-input {
  padding: 12px 20px;
  margin: 15px;
  border-radius: 30px;
  border: none;
  background-color: #ececec;
  font-size: 16px;
  outline: none;
  transition: all 0.3s ease;
}

.member-search-input:focus {
  background-color: #ffffff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.member-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}

.member-list-item {
  padding: 12px 15px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.member-list-item:hover {
  background-color: #ececec;
}

.close-button {
  background: none;
  border: none;
  font-size: 28px;
  color: white;
  cursor: pointer;
}

.no-chats,
.no-members {
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 16px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .chat-container {
    flex-direction: column;
    margin: 0;
    border-radius: 0;
    width: 100%;
    margin: 60px 0;
    
  }

  .chat-list-container {
    width: 100%;
    border-right: none;
    border-radius: 0;
  }

  .chat-window {
    display: none;
  }

  .chat-header {
    justify-content: flex-start;
  }

  .chat-messages {
    padding: 10px;
  }

  .chat-message {
    max-width: 80%;
  }

  .chat-input-container {
    padding: 10px;
  }

  .chat-input {
    padding: 10px 15px;
  }

  .chat-send-button {
    padding: 10px 15px;
  }
}
