/* Main Layout */
.app-wrapper {
    display: flex;
    height: 100vh;
    
  }
  
  .dashboard-subtitle {
    margin-top: 5px;
  }
  
  .main-content {
    padding: 20px 70px;
    background-color: #f0f2f5;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;

   
  }
  
  /* Welcome Section */
  .welcome-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .title-container {
    display: flex;
    flex-direction: column;
  }
  
  .icon-bar {
    display: flex;
    gap: 20px;
    align-items: flex-start; /* Align icons to the top */
    margin-top: 20px;
  }
  
  .top-icon {
    font-size: 34px;
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .top-icon:hover {
    color: #0056b3;
  }
  
  .welcome-section h1 {
    font-size: 28px;
    color: #004a70;
  }
  
  .welcome-section p {
    font-size: 18px;
    color: #555;
  }
  
  /* Cards Section */
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
  }
  
  .card {
    background: linear-gradient(135deg, #0073e6, #3cb3fd);
    color: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 calc(33.333% - 20px);
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .card h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 18px;
  }
  
  .card-icon {
    font-size: 36px;
    margin-bottom: 15px;
  }
  
  /* News Section */
  .news-section {
    margin-top: 40px;
  }
  
  .section-title {
    font-size: 24px;
    color: #004a70;
    margin-bottom: 20px;
  }
  
  .news-card {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }
  
  .news-post {
    border: 1px solid #ccc;
    border-radius: 0; /* No border-radius */
    overflow: hidden;
  }
  
  .news-post-header {
    background-color: #f7f7f7;
    padding: 20px;
  }
  
  .news-post-title {
    font-size: 22px;
    color: #333;
    margin: 0;
  }
  
  .news-post-meta {
    font-size: 14px;
    color: #777;
    margin-top: 5px;
  }
  
  .news-post-body {
    background-color: #fdfdfd;
    padding: 20px;
    font-size: 16px;
    line-height: 1.6;
    color: #444;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .news-post-body img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .news-post-body h1,
  .news-post-body h2,
  .news-post-body h3,
  .news-post-body h4,
  .news-post-body h5,
  .news-post-body h6 {
    color: #004a70;
    margin-top: 20px;
  }
  
  .news-post-body p {
    margin-bottom: 15px;
  }
  
  .news-post-body ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .news-post-body ol {
    list-style-type: decimal;
    padding-left: 20px;
    margin-bottom: 15px;
  }
  
  .news-post-body a {
    color: #007bff;
    text-decoration: none;
  }
  
  .news-post-body a:hover {
    text-decoration: underline;
  }
  
  .news-post-body blockquote {
    border-left: 4px solid #ccc;
    padding-left: 15px;
    color: #666;
    margin: 20px 0;
  }
  
  .news-post-body hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 20px 0;
  }
  
  /* Scrollbar styling */
  .news-post-body::-webkit-scrollbar {
    width: 6px;
  }
  
  .news-post-body::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  /* Statistics Section */
  .statistics-section {
    margin-top: 40px;
  }
  
  .statistics-section h2 {
    font-size: 24px;
    color: #004a70;
    margin-bottom: 20px;
  }
  
  .statistics-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .stat-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(33.333% - 20px);
  }
  
  .stat-item h4 {
    font-size: 18px;
    color: #004a70;
    margin-bottom: 10px;
  }
  
  .stat-item p {
    font-size: 16px;
    color: #333;
  }
  
  .icon-button {
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #007bff;
    transition: color 0.3s;
  }
  
  .footer {
    margin-top: 40px;
    padding: 20px;
    background-color: #f0f2f5;
    text-align: center;
    border-top: 1px solid #ccc;
  }
  
  .footer p {
    font-size: 14px;
    color: #666;
  }
  
  /* Responsive Adjustments */
  
  /* Tablet View (Medium Screens) */
  @media (max-width: 1024px) {
    .card,
    .stat-item {
      flex: 1 1 calc(50% - 20px);
    }
  }
  
  /* Mobile View (Small Screens) */
  @media (max-width: 768px) {
    .main-content {
      padding: 20px 30px;
      margin-top: 50px;
      margin-bottom: 50px;
     
    }
  
    .cards-container {
      flex-direction: column;
    }
  
    .card {
      flex: none;
      width: 100%;
    }
  
    .statistics-container {
      flex-direction: column;
    }
  
    .stat-item {
      flex: 1 1 100%;
    }
  
    .icon-bar {
      margin-top: 10px;
    }
  }
  