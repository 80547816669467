/* src/components/Inbox.css */

.inbox-app-wrapper {
    display: flex;
  }
  
  .inbox-main-content {
    flex: 1;
    padding: 20px;
    background-color: #f0f2f5;
  }
  
  .inbox-section {
    max-width: 1000px; /* Adjusted to make the inbox a bit smaller */
    margin: 0 auto;
  }
  
  .inbox-title {
    text-align: center;
    margin-bottom: 20px;
    color: #004a70;
  }
  
  .inbox-posts-container {
    margin-top: 20px;
  }
  
  .inbox-post-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px 20px;
    margin-bottom: 20px;
    background-color: #fff;
    position: relative;
    transition: background-color 0.3s;
  }
  
  .inbox-post-card:hover {
    background-color: #f9f9f9;
  }
  
  .inbox-post-unread {
    background-color: #e6f7ff; /* Light blue background for unread posts */
  }
  
  .inbox-post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  
  .inbox-post-title {
    margin: 0;
    font-size: 18px;
    color: #004a70;
  }
  
  .inbox-post-date {
    font-size: 14px;
    color: #888;
  }
  
  .inbox-post-new-badge {
    position: absolute;
    top: 15px;
    right: 20px;
    background-color: #1890ff;
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    font-size: 12px;
  }
  
  .inbox-post-expanded {
    margin-top: 10px;
  }
  
  .inbox-post-content-box {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #fafafa;
    margin-bottom: 10px; /* Added margin for spacing */
  }
  
  .inbox-post-content {
    font-size: 16px;
    color: #333;
  }
  
  .inbox-post-message {
    font-size: 16px;
    color: #333;
  }
  
  .inbox-post-link {
    color: #1890ff;
    text-decoration: underline;
    cursor: pointer;
  }
  
  .inbox-no-posts-message {
    text-align: center;
    font-size: 18px;
    color: #888;
  }
  
  .inbox-post-skeleton {
    margin-bottom: 20px;
  }
  .inbox-bottom-text {
    text-align: center;
    margin-top: 20px;
    color: #888;
  }
  
  @media (max-width: 768px) {
    .inbox-section {
      padding: 10px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  
    .inbox-post-title {
      font-size: 16px;
    }
  
    .inbox-post-content {
      font-size: 14px;
    }
  
    .inbox-post-message {
      font-size: 14px;
    }
  }
  