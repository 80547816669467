/* src/components/Spinner.css */

.spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above other elements */
  }
  
  .spinner-logo {
    width: 100px;
    animation: spin-animation 10s cubic-bezier(0.5, 0.1, 0.25, 1) infinite;
  }
  
  /* Animation for speeding up, slowing down, and repeating */
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(360deg);
    }
    30% {
      transform: rotate(720deg);
    }
    50% {
      transform: rotate(1080deg);
    }
    70% {
      transform: rotate(1440deg);
    }
    100% {
      transform: rotate(1800deg);
    }
  }
  