
.signup-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  margin-left: -300px;
}
.sub-text {
  font-size: 15px;
  color: #555;
  margin-bottom: 20px;
}

.logo-container {
  margin-bottom: 20px;
}

.airtrakr-logo {
  width: 150px;
  height: auto;
}

.signup-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.signup-form h2 {
  text-align: center;
  color: #004a70;
  margin-bottom: 20px;
}

h3 {
  color: #004a70;
  margin-bottom: 15px;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.custom-label {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.form-row input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

/* Button Styles */
.form-row button,
.submit-button {
  background-color: #0073e6;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
}

.form-row button:hover,
.submit-button:hover {
  background-color: #005bb5;
}

.submit-button {
  padding: 12px;
  
}

.error {
  color: red;
  text-align: center;
  margin-top: 15px;
}

.success {
  color: green;
  text-align: center;
  margin-top: 15px;
}

@media (min-width: 768px) {
 
  .signup-container {
    padding: 50px;
    
  }

  .signup-form h2 {
    font-size: 24px;
  }

  .form-row {
    flex-direction: row;
    justify-content: space-between;
  }

  .custom-label {
    width: 30%;
    text-align: left;
  }

  .form-row input,
  .form-row button {
    width: 65%;
  }
}
@media (max-width: 768px) {
  .signup-page  {
    margin-left: 0px;
  }

}