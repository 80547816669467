
  
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    margin-left: -300px;
  
  }
  
  .login-logo-container {
    margin-bottom: 20px;
  }
  
  .login-logo {
    width: 200px;
    height: auto;
  }
  
  .login-card {
    background-color: #fff;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .login-heading {
    font-size: 24px;
    color: #004a70;
    margin-bottom: 20px;
  }
  
  .login-form-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .login-label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
    display: block;
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-sizing: border-box; /* Fixes overflow and keeps input aligned properly */
  }
  
  .login-input:focus {
    border-color: #0073e6;
    outline: none;
  }
  
  .login-button {
    width: 100%;
    background-color: #0073e6;
    color: white;
    font-size: 16px;
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #005bb5;
  }
  
  .login-error {
    color: red;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: left;
  }
  .login-footer-text {
    font-size: 12px;
    color: #888;
    text-align: center;
    margin-top: 30px;
  }
  
  .halsen-logo-container {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .halsen-logo {
    opacity: 0.5; /* Semi-transparent */
    width: 100px; /* Adjust the size as needed */
  }
  @media (max-width: 768px) {
    .login-container {
      margin-left: 0px;
    }

  }