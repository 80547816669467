/* PlaneCalendar.css */

/* Welcome Section */
.welcome-section h1 {
    font-size: 28px;
    color: #004a70;
  }
  
  /* Plane Card Styles */
  .plane-card-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .plane-card {
    background-color: #ffffff;
    color: #333;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 280px;
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .plane-card:hover {
    transform: translateY(-5px);
  }
  
  .plane-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .plane-icon {
    font-size: 40px;
    color: #0073e6;
    margin-bottom: 10px;
  }
  
  .plane-card h3 {
    font-size: 20px;
    margin: 0;
  }
  
  .plane-model,
  .plane-color,
  .plane-availability {
    font-size: 14px;
    margin: 5px 0;
  }
  
  .plane-availability {
    font-weight: bold;
  }
  
  .plane-availability.available {
    color: green;
  }
  
  .plane-availability.unavailable {
    color: red;
  }
  
  .select-button {
    background-color: #0073e6;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
  }
  
  .select-button:hover {
    background-color: #005bb5;
  }
  
  .no-planes-message {
    font-size: 18px;
    color: #555;
    text-align: center;
    margin-top: 40px;
  }
  
  /* Back Button */
  .back-button {
    background: #e74c3c;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button:hover {
    background: #c0392b;
  }
  
  .back-button:focus {
    outline: none;
  }
  
  /* Scheduler Styles */
  .scheduler-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    margin: 20px auto;
    overflow-x: auto;
  }
  
  .calendar-heading {
    font-size: 24px;
    color: #004a70;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Booking Modal */
  .booking-modal {
    width: 450px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin: 50px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .booking-modal h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .form-group textarea {
    resize: vertical;
  }
  
  .error {
    border-color: red;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
  }
  
  /* Button Group */
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .button-group button {
    width: 48%;
  }
  
  /* Weight and Balance Modal */
  .wb-modal {
    width: 450px;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    margin: 50px auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .wb-modal h2 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #333;
  }
  
  /* Weight and Balance Graph */
  .wb-graph-container {
    margin: 20px auto;
    width: 100%;
    max-width: 400px;
  }
  
  /* Booking Details */
  .wb-details h3 {
    margin-top: 20px;
    color: #333;
  }
  
  /* Responsive styling for mobile */
  @media (max-width: 768px) {
    .scheduler-container {
      width: 100%;
      margin: 20px 0;
    }
  
    .booking-modal,
    .wb-modal {
      width: 90%;
      margin: 20px auto;
    }
  
    .plane-card {
      width: 100%;
      margin: 10px 0;
    }
  
    .create-booking-button-container {
      text-align: center;
    }
  
    .button-group {
      flex-direction: column;
    }
  
    .button-group button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  