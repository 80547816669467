/* Main container for the admin controls */
.admin-controls {
  background-color: #f0f2f5;
}

.admin-controls-container {
  margin-left: 50px; /* Adjust for the sidebar width */
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 12px;
}

.admin-heading {
  color: #004a70;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}

/* Modern Navbar styles */
.admin-navbar {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.nav-item {
  font-size: 16px;
  color: #004a70;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 0; /* Remove border-radius */
  background: none; /* Remove background */
  border: none; /* Remove border */
  cursor: pointer;
  transition: color 0.2s ease, border-bottom 0.2s ease;
}

.nav-item.active,
.nav-item:hover {
  border-bottom: 2px solid #0073e6; /* Underline for the active state */
  color: #0073e6;
}

.nav-item:focus {
  outline: none;
}

.nav-item:active {
  color: #005bb5;
}

/* Table styles */
.members-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.members-table th,
.members-table td {
  padding: 12px 15px;
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.members-table th {
  background-color: #f1f1f1;
  color: #004a70;
}

.members-table td {
  color: #333;
}

.members-table tbody tr:hover {
  background-color: #e8f4ff;
  cursor: pointer;
}

/* Button styles */
.button-container {
  display: flex;
  gap: 8px;
}

.reset-button,
.delete-button,
.edit-button,
.edit-role-button {
  padding: 8px 14px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Specific styles for buttons */
.reset-button {
  background-color: #4caf50;
  color: #ffffff;
}

.reset-button:hover {
  background-color: #43a047;
}

.delete-button {
  background-color: #e53935;
  color: #ffffff;
  pointer-events: auto;
}

.delete-button:hover {
  background-color: #d32f2f;
}

.edit-button {
  background-color: #0073e6;
  color: #ffffff;
}

.edit-button:hover {
  background-color: #005bb5;
}

/* Loading message */
.loading-message {
  text-align: center;
  font-size: 16px;
  color: #0073e6;
  margin-top: 20px;
}

/* Form styles */
.plane-creation-form,
.add-member-form,
.create-post-form {
  background-color: #ffffff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  max-width: 700px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
}

.error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
}

/* Buttons */
.create-plane-button,
.create-member-button,
.create-post-button,
.submit-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 150px;
  margin-top: 10px;
}

.create-plane-button,
.create-member-button,
.create-post-button {
  background-color: #0073e6;
  color: white;
}

.create-plane-button:hover,
.create-member-button:hover,
.create-post-button:hover {
  background-color: #005bb5;
}

.submit-button {
  background-color: #4caf50;
  color: white;
  width: 100%;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #45a049;
}

.cancel-button {
  background-color: #e53935;
  color: white;
  margin-top: 20px;
}

.cancel-button:hover {
  background-color: #d32f2f;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.planes-list,
.posts-list {
  margin-top: 20px;
}

/* Posts list styles */
.posts-list table {
  width: 100%;
}

.posts-list th,
.posts-list td {
  padding: 12px 15px;
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.posts-list th {
  background-color: #f1f1f1;
  color: #004a70;
}

.posts-list td {
  color: #333;
}

.posts-list tbody tr:hover {
  background-color: #e8f4ff;
}

/* Plane details */
.plane-details p {
  margin: 5px 0;
}

.owner-form hr {
  margin: 20px 0;
}

/* Clickable table row */
.clickable-row:hover {
  background-color: #e8f4ff;
  cursor: pointer;
}

.clickable-row td {
  cursor: pointer;
}

/* Lowkey Button */
.lowkey-button {
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
  margin: 5px 0 0 0;
}

.lowkey-button:hover {
  background-color: #e7e7e7;
  border-color: #bbb;
}

.lowkey-button:active {
  background-color: #ddd;
  border-color: #aaa;
}

/* Add Owner Button */
.add-owner-button {
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #5cb85c;
  background-color: #f7f7f7;
  color: #5cb85c;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
  margin: 5px;
}

.add-owner-button:hover {
  background-color: #dff0d8;
  border-color: #4cae4c;
}

.add-owner-button:active {
  background-color: #c8e5bc;
  border-color: #4cae4c;
}

/* Remove Owner Button */
.remove-owner-button {
  padding: 6px 10px;
  font-size: 14px;
  border: 1px solid #d9534f;
  background-color: #f7f7f7;
  color: #d9534f;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
  margin: 5px;
}

.remove-owner-button:hover {
  background-color: #f2dede;
  border-color: #d43f3a;
}

.remove-owner-button:active {
  background-color: #ebcccc;
  border-color: #d43f3a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  /* Remove the margin on mobile devices */
  .admin-controls-container {
    margin-left: 10px; /* Adjust for the sidebar width */
    margin-top: 20px;
  }

  .nav-item {
    font-size: 14px;
    padding: 8px 12px;
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  .submit-button,
  .cancel-button {
    width: 100%;
  }
}
