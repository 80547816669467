/* src/components/SelectClub.css */

.select-club-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 20px;
    margin-left: -300px;
  }
  
  .logo-container {
    margin-bottom: 20px;
  }
  
  .airtrakr-logo {
    width: 150px;
    height: auto;
  }
  
  .select-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #004a70;
  }
  
  .club-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .club-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  .club-name {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .select-button {
    background-color: #0073e6;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .select-button:hover {
    background-color: #005bb5;
  }
  
  .divider {
    font-size: 16px;
    margin: 25px 0;
    color: #777;
    position: relative;
    margin-bottom: 50px;
  }
  
  .divider span {
   
    padding: 0 10px;
    position: relative;
    z-index: 1;
    
  }
  .logout-button-select-club {
    background-color: #e74c3c;
    margin-top: 10px;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 6px;
    cursor: pointer;
    width: 200px;
    height: 50px;
    transition: background-color 0.3s ease;
  }
  
  .logout-button-select-club:hover {
    background-color: #c039
    2b;
  }
  @media (max-width: 768px) {
    .select-club-page {
      margin-left: 0px;
    }

  }