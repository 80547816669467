/* Logbook.css */

/* Main Layout */
.app-wrapper {
  display: flex;
  height: 100vh;
}



/* Logbook Section */
.logbook-section {
  margin-top: 20px;
}

.logbook-section h1 {
  font-size: 28px;
  color: #004a70;
  margin-bottom: 20px;
}

.add-entry-button {
  background-color: #004a70;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-entry-button:hover {
  background-color: #003354;
}

.logbook-table {
  margin-top: 20px;
  overflow-x: auto;
}

/* Modal Styles */
.logbook-modal {
  width: 80%;
  max-width: 800px;
  background-color: white;
  border-radius: 10px;
  padding: 30px;
  margin: 50px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

/* Form Grid */
.form-section {
  margin-bottom: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.details-group p {
  margin-bottom: 10px;
  color: #333;
}

.details-group strong {
  color: #004a70;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
    
  }

  .button-group {
    flex-direction: column;
    align-items: stretch;
  }
}

/* Flight Time Grid */
.flight-time-grid {
  display: grid;
  grid-template-columns: 1fr 80px 80px;
  gap: 8px;
  align-items: center;
}

.flight-time-row {
  display: contents;
}

.flight-time-row.header {
  font-weight: bold;
}

.flight-time-row > div,
.flight-time-row > .MuiTextField-root {
  margin-bottom: 8px;
}

.flight-time-row > div:first-child {
  text-align: left;
}

.flight-time-row > .MuiTextField-root {
  width: 100%;
}

/* Map Container */
.map-container {
  margin-top: 20px;
}
/* Details Paper */
.details-paper {
  padding: 20px;
  margin-bottom: 20px;
}

/* Details Grid */
.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.details-grid p {
  margin: 0;
}

.details-grid strong {
  color: #004a70;
}