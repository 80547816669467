/* Sidebar.css */

/* Apply a left margin to the body to account for the sidebar */
body {
  margin-left: 300px;
  background-color: #f0f2f5;
}

/* Sidebar Styles */
.sidebar {
  width: 300px;
  background-color: #192f3a;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  border-radius: 0 30px 0 0;
}

.sidebar-content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

.sidebar-footer {
  padding: 20px;
  position: relative;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.nav {
  flex-grow: 1;
}

.nav-button {
  width: 96%;
  padding: 15px;
  font-size: 18px;
  background-color: #192f3a;
  color: #eef4fa;
  border: none;
  border-radius: 30px;
  text-align: left;
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.nav-button.selected {
  background-color: #eef4fa;
  color: #004a70;
  border-radius: 30px 0 0 30px;
}

.nav-button:hover {
  background-color: #d1e3f3;
}

/* Alert Button */
.alert-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 30px;
  margin-bottom: 10px;
}

/* Grouping the alert buttons together on the same line */
.icon-group {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.alert-icon {
  font-size: 30px;
}

.alert-button:hover .alert-icon {
  color: #dbdbdb;
}

/* Divider */
.divider {
  height: 2px;
  background-color: #eef4fa70;
  margin: 20px 0;
}

.divider-2 {
  height: 2px;
  background-color: #eef4fa70;
  margin: 20px 0;
}

/* UTC Time Box */
.utc-display {
  width: 100%;
  color: #eef4fa;
  text-align: center;
  font-size: 30px;
  padding: 0;
  border-radius: 8px;
  margin-bottom: 20px;
}

.profile {
  text-align: center;
  position: relative;
}

.profile-name {
  margin-top: 10px;
  font-size: 16px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #004a70;
  background-size: cover;
}

.logout-button {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  background-color: #192f3a;
  color: #e77f7f;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.logout-button:hover {
  background-color: #d1e3f3;
}

/* Extended Profile */
.extended-profile {
  position: fixed;
  top: calc(100vh - 400px); /* Move the extended profile higher */
  left: 260px; /* Slightly overlap the sidebar */
  width: 350px; /* Increased width */
  max-height: 400px; /* Increased height */
  background-color: #192f3a;
  color: #fff;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
}

.extended-profile.expanded {
  transform: translateX(20px); /* Move more to the right */
  opacity: 1;
  visibility: visible;
}

.extended-content {
  padding: 20px;
}

.extended-content p {
  margin: 10px 0;
}

.extended-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.profile-page-button,
.synchronize-button {
  padding: 10px;
  font-size: 16px;
  background-color: #004a70;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s;
}

.profile-page-button:hover,
.synchronize-button:hover {
  background-color: #006494;
}

.sync-icon {
  margin-right: 5px;
}

/* Mobile Header */
.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #192f3a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  z-index: 1000;
}

.burger-button {
  position: absolute;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}

.burger-button svg {
  width: 30px;
  height: 30px;
  color: #fff;
}

.mobile-logo {
  height: 40px;
}

/* Mobile Sidebar */
.mobile-sidebar {
  position: fixed;
  top: 60px; /* Start below the header */
  left: 0;
  width: 100%;
  height: calc(100% - 60px); /* Adjust height */
  background-color: #192f3a;
  color: #fff;
  z-index: 999;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.mobile-sidebar.open {
  transform: translateX(0);
}

.mobile-sidebar .sidebar-content {
  padding: 20px;
}

.mobile-sidebar .sidebar-footer {
  padding: 20px;
}

/* Bottom Navigation Bar */
.bottom-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #192f3a;
  height: 75px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 800;
}

.nav-icon {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 15px;
}

.nav-icon:hover {
  color: #dbdbdb;
}

.nav-main-button {
  background-color: #004a70;
  color: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  position: relative;
  bottom: 20px; /* To make it overlap the navigation bar */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.nav-main-button:hover {
  background-color: #006494;
}

/* Adjustments for Mobile */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  body {
    margin-left: 0;
    overflow-x: hidden;
 
  }

  /* Content padding to avoid overlap with mobile header */
  .content {
    padding-top: 60px;
  }

  .nav-button {
    font-size: 20px;
    padding: 20px;
  }

  .utc-display {
    font-size: 36px;
  }

  .logout-button {
    font-size: 20px;
    padding: 20px;
  }

  /* Hide Extended Profile on Mobile */
  .extended-profile {
    display: none;
  }
}

@media (min-width: 769px) {
  .bottom-nav {
    display: none;
  }
}
